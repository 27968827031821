import React, { useState } from "react"

import Modal from "./Modal"
import VideoPlayer from "./VideoPlayer"

const ModalVideo = ({ className, event, poster, sources, previewSources, rootElement }) => {
	const [modalOpen, setModalOpen] = useState(false)

	const close = () => setModalOpen(false)
	const open = () => {
		if (event) {
			window.umami && window.umami.track(event)
		}
		setModalOpen(true)
	}

	return (
		<div className={className}>
			<div
				className="relative w-full h-full rounded-medium overflow-hidden group hover:cursor-pointer flex flex-col justify-center bg-tealblue group"
				onClick={() => (modalOpen ? close() : open())}>
				{modalOpen && (
					<Modal modalOpen={modalOpen} handleClose={close} rootElement={rootElement}>
						<VideoPlayer
							autoPlay={true}
							source={{
								type: "video",
								sources: sources,
							}}
						/>
					</Modal>
				)}

				{previewSources && (
					<video
						className="w-full h-full transition duration-200 object-cover"
						loop={true}
						autoPlay={true}
						muted={true}
						poster={poster}
						playsInline>
						{previewSources.map(({ src, type }) => (
							<source key={src} src={src} type={type} />
						))}
					</video>
				)}
				{!previewSources && poster && (
					<div className="w-full h-full transition duration-200 object-cover">
						<img src={poster} alt="" className="" />
					</div>
				)}
				<div className="absolute inset-0 bg-black opacity-20 transition duration-200 group-hover:opacity-10"></div>
				<div className="absolute inset-0 bg-tealblue opacity-30 transition duration-200 group-hover:opacity-10"></div>

				<div className="absolute inset-0 flex flex-col justify-center items-center">
					<svg
						viewBox="0 0 100 100"
						xmlns="http://www.w3.org/2000/svg"
						className="text-white opacity-80 group-hover:opacity-100 fill-current
			w-[75px] h-[75px] lg:w-[90px] lg:h-[90px] transition duration-200 group-hover:scale-[1.1]">
						<path d="M50 100C22.386 100 0 77.614 0 50S22.386 0 50 0s50 22.386 50 50-22.386 50-50 50zm-9.273-68.33a1.206 1.206 0 00-.648-.189 1.18 1.18 0 00-1.19 1.169v34.7c0 .226.066.447.192.636a1.204 1.204 0 001.646.344l27.249-17.35a1.155 1.155 0 000-1.96l-27.25-17.35z" />
					</svg>
				</div>
			</div>
		</div>
	)
}

export default ModalVideo
